* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  user-select: none;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}
